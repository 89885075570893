import React, { createContext, useState, useEffect, useContext } from "react";
import { onAuthStateChanged } from "firebase/auth";
import { auth } from './firebase';
import logo from './img/unbound-logo-bg-trans-bc-black.png';

// Create context
export const AuthContext = createContext();

// Provider component
export function AuthProvider({ children }) {
  const [user, setUser] = useState(null);
  const [loadingAnimation, setLoadingAnimation] = useState(true);
  const [loadingContext, setLoadingContext] = useState(null);
  const helpPlaceholders = [
    'Using names and locations in your Logs can help you find them more accurately.',
    'The more you Log, the more you\'ll be able to find, later.',
    'The things you log are the things Unbound can help you resurface.',
  ];

  const [helpPlaceholder, setHelpPlaceholder] = useState(helpPlaceholders[Math.floor(Math.random() * helpPlaceholders.length)]);

  useEffect(() => {
    const unsubscribe = onAuthStateChanged(auth, (user) => {
      console.log('AuthProvider user:', user);
      setUser(user);
      setLoadingAnimation(false);
    });
    // Update helpPlaceholder when loadingContext changes
    if (loadingContext === 'LookupHistory') {
      setHelpPlaceholder(helpPlaceholders[Math.floor(Math.random() * helpPlaceholders.length)]);
    }
    // Cleanup subscription on unmount
    return () => unsubscribe();
  }, []);

  if (loadingAnimation) {
    return (
      <div className="flex flex-col justify-center items-center h-screen">
        <img src={logo} className="animate-pulse-logo h-12 w-12" alt="Loading..." />
        {loadingContext === 'LookupHistory' &&
          <div className="text-center text-slate-800 font-serif text-md w-2/3 sm:w-1/3 mx-auto mt-12">
            <span className="font-bold">Processing your Lookup.</span>
            <br /><br />
            <span className="italic">{helpPlaceholder}</span>
          </div>
        }
      </div>
    );
  }

  return (
    <AuthContext.Provider value={{ auth, user, setUser, setLoadingAnimation, setLoadingContext }}>
      {children}
    </AuthContext.Provider>
  );
}
