// HISTORY CARD COMPONENT

import React, { useEffect, useRef, useState, useContext } from 'react';
import { AuthContext } from './authContext.js';
import { updateDoc, field } from "firebase/firestore";

import { db } from './firebase';
import { collection, doc, getDoc, setDoc } from "firebase/firestore";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPenToSquare, faArrowUp, faXmark, faTrashCan, faAnglesDown, faAnglesUp } from '@fortawesome/free-solid-svg-icons';

function HistoryCard({ lookup, log, editData, setLookupHistory, setLogHistory }) {
  const { user, setLoadingAnimation } = useContext(AuthContext);
  const { text: lookupText, responseText, timestamp: lookupTimestamp } = lookup || {};
  const { text: logText, timestamp: logTimestamp } = log || {};
  const text = lookup ? `${lookupText}` : `${logText}`;
  const textLabel = lookup ? `Lookup:` : `Log:`;
  const timestamp = lookup ? lookupTimestamp : logTimestamp;
  const date = timestamp ? timestamp.toDate() : null;  // Convert Firestore Timestamp to JavaScript Date
  const formattedTime = date ? date.toLocaleString('en-US', { hour: 'numeric', minute: 'numeric', hour12: true }) : null;
  const formattedDate = date ? date.toLocaleDateString('en-US') : null;
  // for handling unix timestamps in milliseconds (timestampUnixMs field)
  // const formattedTime = timestamp ? new Date(timestamp).toLocaleString('en-US', { hour: 'numeric', minute: 'numeric', hour12: true }) : null;
  // const formattedDate = timestamp ? new Date(timestamp).toLocaleDateString('en-US') : null;

  const contentRef = useRef(null);
  const [hasOverflow, setHasOverflow] = useState(false);
  const [isExpanded, setIsExpanded] = useState(false); // showing the relevant logs pursuant to a given lookup
  const candidateListRef = useRef(null);
  const topOfExpandedContentRef = useRef(null);

  const responseContentRef = useRef(null);
  const [hasResponseOverflow, setHasResponseOverflow] = useState(false);

  // Calculate the heights based on the viewport height and the flex values
  const textAndResponseHeight = `${(6 * 65) / (6 + 6 + 1)}vh`;
  const timestampHeight = `${(1 * 65) / (6 + 6 + 1)}vh`;

  // DELETE
  const [showDeleteConfirm, setShowDeleteConfirm] = useState(false);

  const handleDeleteClick = () => {
    setShowDeleteConfirm(true);
  };

  const handleConfirmDelete = async () => {
    // Get the existing document reference
    const oldDocumentRef = lookup ? doc(db, "lookups", lookup.id) : doc(db, "logs", log.id);

    // Update the document to set the 'deleted' field to true
    await updateDoc(oldDocumentRef, {
      deleted: true
    });

    // Remove the deleted entry from the state array
    if (lookup) {
      setLookupHistory(prev => prev.filter(l => l.id !== lookup.id));
    } else {
      setLogHistory(prev => prev.filter(l => l.id !== log.id));
    }

    // Hide the confirmation modal
    setShowDeleteConfirm(false);
  };

  const handleCancelDelete = () => {
    // Hide the confirmation modal
    setShowDeleteConfirm(false);
  };

  // EDIT
  const [isEditing, setIsEditing] = useState(false);
  const [editedText, setEditedText] = useState("");

  const handleEditClick = () => {
    setIsEditing(true);
    setEditedText(text);
  };

  // if editing and new text is different than old text, create a new data object that is a copy of the old data, but with the text field updated and the prevId field added
  const handleSaveClick = async () => {
    setLoadingAnimation(true);
    setIsEditing(false);
    try {
      const oldData = lookup || log;

      // get the existing document reference
      const oldDocumentRef = lookup ? doc(db, "lookups", lookup.id) : doc(db, "logs", log.id);

      // read the existing document data
      const oldDocSnapshot = await getDoc(oldDocumentRef);

      if (oldDocSnapshot.exists()) {
        const oldData = {
          id: oldDocSnapshot.id, // include the document id
          ...oldDocSnapshot.data() // include the document data
        };

        const newData = await editData(oldData, editedText, lookup !== undefined, user, setLoadingAnimation);

        // Check if the old data and new data are different
        if (JSON.stringify(oldData) !== JSON.stringify(newData)) {
          // If they are different, mark the old data as deleted
          await updateDoc(oldDocumentRef, {
            deleted: true
          });
        }

        // Replace the old data with the new data in the log or lookup history
        if (lookup) {
          setLookupHistory(prev => prev.map(lookup => lookup.id === oldData.id ? { ...lookup, ...newData } : lookup));
        } else {
          setLogHistory(prev => prev.map(log => log.id === oldData.id ? { ...log, ...newData } : log));
        }
      }
    } catch (error) {
      console.error("Error during editing:", error);
    } finally {
      setLoadingAnimation(false);
    };
  };

  // KEYBOARD SHORTCUTS
  const handleKeyDown = (e) => {
    // editing
    if (e.key === 'Escape' && isEditing) {
      setIsEditing(false);
      setEditedText(text); // Reset any changes
    }
    if (e.key === 'e') {
      setIsEditing(true);
    }
    // Save on Ctrl+Enter or Cmd+Enter
    if ((e.key === 'Enter' && e.metaKey) || (e.key === 'Enter' && e.ctrlKey)) {
      e.preventDefault();
      handleSaveClick();
    }
    // for Mac: backspace key that is labeled "delete" on Mac keyboards
    if (e.key === 'Backspace' && navigator.platform.indexOf('Mac') > -1) {
      handleDeleteClick();
    }
    // For Windows: Actual delete key
    if (e.key === 'Delete' && navigator.platform.indexOf('Win') > -1) {
      handleDeleteClick();
    }
    // Confirm delete on Enter when delete confirmation is showing
    if (e.key === 'Enter' && showDeleteConfirm) {
      handleConfirmDelete();
    }
    // Cancel delete on Escape when delete confirmation is showing
    if (e.key === 'Escape' && showDeleteConfirm) {
      handleCancelDelete();
    }
  };

  useEffect(() => {
    // Add event listener
    document.addEventListener('keydown', handleKeyDown);

    // Remove event listener on cleanup
    return () => {
      document.removeEventListener('keydown', handleKeyDown);
    };
  }, [isEditing, text, showDeleteConfirm]);

  const checkOverflow = (ref, setOverflowState) => {
    if (ref.current) {
      const element = ref.current;
      if (element.scrollHeight > element.clientHeight) {
        setOverflowState(true);
      } else {
        setOverflowState(false);
      }
    }
  };

  // custom double click handler to select all text in the textarea
  const [clickCount, setClickCount] = useState(0);
  const handleClick = (e) => {
    setClickCount(prevCount => prevCount + 1);
    setTimeout(() => {
      setClickCount(0);
    }, 250);

    if (clickCount >= 1) {
      e.target.select();
    }
  };

  useEffect(() => {
    checkOverflow(contentRef, setHasOverflow);
    checkOverflow(responseContentRef, setHasResponseOverflow);
    const handleResize = () => {
      checkOverflow(contentRef, setHasOverflow);
      checkOverflow(responseContentRef, setHasResponseOverflow);
    }
    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, [text, responseText]);

  const textareaRef = useRef(null);

  useEffect(() => {
    if (isEditing && textareaRef.current) {
      textareaRef.current.focus();
      textareaRef.current.setSelectionRange(
        textareaRef.current.value.length,
        textareaRef.current.value.length
      );
    }
  }, [isEditing]);

  function highlightText(text, highlights) {
    if (!highlights || highlights.length === 0) {
      return text;
    }
    let newText = text;
    for (const highlight of highlights) {
      const regex = new RegExp(`\\b${highlight}\\b`, 'ig');
      newText = newText.replace(regex, `<mark class="bg-highlight">$&</mark>`);
    }
    return newText;
  }

  // can double click to select all text in the textarea
  // const handleDoubleClick = () => {
  //   if (textareaRef.current) {
  //     textareaRef.current.select();
  //   }
  // };

  // when opening the relevant logs expanded view, scroll to the top of the list smoothly
  useEffect(() => {
    if (isExpanded && topOfExpandedContentRef.current) {
      topOfExpandedContentRef.current.scrollIntoView({ behavior: 'smooth' });
    }
  }, [isExpanded]);

  // Set up the requirements for filtering candidate_array maps. 
  let filteredCandidates;
  // Ensure that candidateArray exists and is an array
  if (Array.isArray(lookup?.candidateArray)) {
    // Start by sorting the candidates based on their relevance
    let sortedCandidates = [...lookup.candidateArray].sort((a, b) => {
      return a.relevance - b.relevance;
    });

    filteredCandidates = sortedCandidates;
    // // Get the most relevant candidate (the first one after sorting)
    // const mostRelevantCandidate = sortedCandidates[0];
    // // Filter the candidates based on these rules. Removing this for now, and just relying on the automatic filtering done by Weviate. Keeping "filteredCandidates" variable in place even though there is no filter, to make it easier to re-filter in future
    // filteredCandidates = sortedCandidates.filter(candidate => {
    //   if (candidate === mostRelevantCandidate && mostRelevantCandidate.relevance < 0.6) {
    //     // Include the most relevant candidate if its relevance is within 0.6 of 1
    //     return true;
    //   } else if (candidate.relevance < 0.05) {
    //     // Include a candidate if its relevance is within 0.05 of 1
    //     return true;
    //   } else if (candidate.relevance < 0.15 && !sortedCandidates.some(c => c.relevance < 0.05)) {
    //     // Include a candidate if its relevance is within 0.15 of 1, and there is no candidate where the absolute difference is less than 0.05
    //     return true;
    //   } else if (candidate.relevance < 0.3 && !sortedCandidates.some(c => c.relevance < 0.15)) {
    //     // Include a candidate if its relevance is within 0.3 of 1, and there is no candidate where the absolute difference is less than 0.15
    //     return true;
    //   }
    //   return false;
    // });
  }
  if (!log && !lookup) {
    return null;
  }
  // a delay here before returning null so it doesn't flash the null card doesn't work

  return (
    <div
      className={`p-4 px-5 shadow-slate-700/20 shadow-md 
          text-white font-serif rounded-lg m-auto w-4/5 sm:w-2/3 md:w-1/2 lg:w-1/3 
          flex flex-col justify-evenly items-center z-50 relative`}
      style={{
        height: '65vh',
        backgroundColor: showDeleteConfirm ? `#D1D5DA` : `#2fc4fa`,
        backgroundImage: showDeleteConfirm ? 'none'
          : `
            radial-gradient(circle at 30% 30%, rgba(255, 255, 255, 0.5), transparent),
            radial-gradient(circle at center, #1FADE1 30%, #2fc4fa 100%),
            linear-gradient(#2fc4fa, #2fc4fa) content-box,
            linear-gradient(rgba(255, 255, 255, 0.1), rgba(255, 255, 255, 0.1)),
            linear-gradient(#2fc4fa, #1FADE1),
            radial-gradient(circle at center, #1FADE1, #2fc4fa)
          `,
        boxShadow: `
          0 8px 12px rgba(0, 0, 0, 0.1), 
          0 5px 6px rgba(0, 0, 0, 0.1), 
          inset 0 6px 12px rgba(255, 255, 255, 0.8),
          inset 0 -5px 15px rgba(0, 0, 0, 0.4)
        `,
        // border: 'double 1px transparent',
        borderRadius: '20px',
      }}
    >
      {isEditing ? (
        <div
          className="relative flex flex-col w-full justify-center items-center"
          style={{ flex: lookup ? 6 : 12 }}
        >
          <div className="relative flex-grow w-full max-h-full pb-9 flex flex-col justify-center items-center">
            <textarea
              ref={textareaRef}
              className="flex-grow w-full text-slate-800 font-normal overflow-auto text-md rounded-md p-3 outline-none"
              style={{ flex: '1', boxSizing: 'border-box' }}
              onClick={handleClick}
              onContextMenu={e => e.preventDefault()} // prevent right-click menu from showing
              value={editedText}
              onChange={(e) => setEditedText(e.target.value)}
              onKeyDown={handleKeyDown}
            />
            <button
              className={`absolute right-3 bottom-11 w-8 h-8 flex items-center justify-center rounded-full 
                ${editedText !== text && editedText.length > 0 ? 'bg-action-active' : 'bg-action-inactive'}`}
              style={{
                boxShadow: editedText !== text && editedText.length > 0 ? `
                  0 3px 5px rgba(0, 0, 0, 0.1), 
                  0 2px 3px rgba(0, 0, 0, 0.06), 
                  inset 0 1px 2px rgba(255, 255, 255, 0.6),
                  inset 0 -1px 3px rgba(0, 0, 0, 0.4)
                  ` : `
                  0 3px 5px rgba(0, 0, 0, 0.1), 
                  0 2px 3px rgba(0, 0, 0, 0.06), 
                  inset 0 1px 2px rgba(255, 255, 255, 0.5),
                  inset 0 -1px 3px rgba(0, 0, 0, 0.25)`,
                background: editedText !== text && editedText.length > 0 ? `
                  radial-gradient(circle at center, rgba(255, 255, 255, 0.2), transparent),
                  linear-gradient(45deg, #004E98, #004E98)
                  ` : `
                  radial-gradient(circle at center, rgba(255, 255, 255, 0.3), transparent),
                  linear-gradient(45deg, #D7E0E3, #D7E0E3)`
              }}
              onClick={() => {
                // Blur the input (closes keyboard on mobile)
                if (textareaRef.current) {
                  textareaRef.current.blur();
                }
                // Delay the execution of the rest of the click event
                setTimeout(() => {
                  if (editedText !== text && editedText.length > 0) {
                    handleSaveClick(editedText);
                  } else {
                    setIsEditing(false);
                    setEditedText(text); // Reset any changes
                  }
                }, 0);
              }}
            >
              <FontAwesomeIcon icon={editedText !== text && editedText.length > 0 ? faArrowUp : faXmark} color="white" />
            </button>
          </div>
        </div>
      ) : (
        <div
          className="relative w-full flex items-center justify-center"
          style={{ height: textAndResponseHeight, flex: lookup ? 6 : 12 }}
        >
          <div
            className="relative p-3 inline-block max-w-full text-slate-800 text-center overflow-auto bg-background-content rounded-md border border-slate-300"
            ref={contentRef} tabIndex={0}
            style={{ justifyContent: 'start', touchAction: 'pan-y', maxHeight: '100%' }}
          >
            {text && (
              <>
                <span className="font-bold"> {textLabel} </span>
                <br />
                <span className="" dangerouslySetInnerHTML={{ __html: highlightText(text, lookup ? lookup?.highlights : log?.highlights) }} />
              </>
            )}
          </div>
        </div>
      )}
      {lookup && (
        <div
          className="relative w-full flex items-center justify-center"
          style={{ height: textAndResponseHeight, flex: 6 }}
        >
          <div
            ref={responseContentRef}
            className="relative p-3 inline-block max-w-full text-slate-800 text-center overflow-auto bg-background-content rounded-md border border-slate-300"
            tabIndex={0}
            style={{ justifyContent: 'start', height: hasResponseOverflow ? '100%' : 'auto', touchAction: 'pan-y', maxHeight: '100%' }}
          >
            {responseText && (
              <>
                <span className="font-bold"> Response: </span>
                <br />
                <span className="" dangerouslySetInnerHTML={{ __html: highlightText(responseText, lookup?.highlights) }}></span>
              </>
            )}
            {isExpanded && (
              <>
                <div ref={topOfExpandedContentRef} />
                {filteredCandidates && filteredCandidates.length > 0
                  ? filteredCandidates.map((candidateObj, index) => (
                    <div
                      className="mt-4 p-3 bg-background-content-dark text-center rounded-md"
                      ref={candidateListRef}
                      key={index}
                    >
                      <span className="font-bold">Relevant Log:</span>
                      <br />
                      <span dangerouslySetInnerHTML={{ __html: highlightText(candidateObj.candidate, lookup?.highlights) }}></span>
                    </div>
                  ))
                  : <div className="mt-4 p-3 bg-background-content-dark text-center rounded-md">
                    <span>No relevant Logs.</span>
                  </div>
                }
              </>
            )}
          </div>
          <button
            onClick={() => setIsExpanded(!isExpanded)}
            className="ml-2 cursor-pointer z-51"
          >
            <FontAwesomeIcon
              icon={isExpanded ? faAnglesUp : faAnglesDown}
              color="#FFFFFF"
            />
          </button>
        </div>
      )}
      <div
        className="pt-3 pb-1 flex flex-col text-white text-base text-center justify-end items-center"
        style={{ height: timestampHeight, flex: 1 }}
      >
        {(formattedDate && formattedTime) ? `${formattedDate} at ${formattedTime}` : "Just now"}
      </div>
      <button
        className="absolute bottom-3 left-3 p-2 cursor-pointer"
        onClick={handleEditClick}
      >
        <FontAwesomeIcon icon={faPenToSquare} color="#FFFFFF" />
      </button>
      <button
        className="absolute bottom-3 right-3 p-2 cursor-pointer"
        onClick={handleDeleteClick}
      >
        <FontAwesomeIcon icon={faTrashCan} color="white" />
      </button>
      {showDeleteConfirm && (
        <div className="absolute inset-0 flex items-center justify-center z-50">
          <div className="bg-white p-4 rounded-md shadow-md shadow-slate-700/60 w-3/4 text-center text-slate-800">
            <p>Are you sure you want to delete this input?</p>
            <div className="mt-4">
              <button className="mx-2 py-1 px-3 shadow-slate-700/50 shadow-sm text-white bg-error-red rounded-md" onClick={handleConfirmDelete}>
                Yes
              </button>
              <button className="mx-2 py-1 px-3 shadow-slate-700/50 shadow-sm text-black bg-gray-300 rounded-md" onClick={handleCancelDelete}>
                No
              </button>
            </div>
          </div>
        </div>
      )}
    </div>
  );
}
export default React.memo(HistoryCard);
