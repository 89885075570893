import weaviate from 'weaviate-ts-client';

export const client = weaviate.client({
    scheme: 'https',
    host: 'obqlnbsqs1mlbsldx8jzfq.c0.us-east4.gcp.weaviate.cloud',
    apiKey: new weaviate.ApiKey('1l7h9umEY1gR81kXkwEakalTL72ku1u6N7mc')
});


export async function storeLogVector(vector, logText, userId, docId, environment, deleted) {
    return client.data
        .creator()
        .withClassName("Entry")
        .withProperties({
            text: logText,
            uid: userId,
            docid: docId,
            environment: environment,
            deleted: deleted,
        })
        .withVector(vector)
        .do()
};


export async function queryVector(vector, userId) {
    // console.log(vector);
    return client.graphql
        .get()
        .withClassName('Entry')
        .withFields("text docid uid _additional { distance }")
        .withNearVector({ vector: vector })
        .withAutocut(1)
        .withWhere({
            path: ["uid"],
            operator: "Equal",
            valueString: userId
        })
        .do()
};
