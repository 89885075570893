// firebase configuration

import { initializeApp } from "firebase/app";
import { getAnalytics } from "firebase/analytics";
import { getAuth } from "firebase/auth";
import { getFirestore } from "firebase/firestore";  
import { getFunctions, httpsCallable } from "firebase/functions";

const firebaseConfig = {
  apiKey: "AIzaSyBl9FEz-XFsiYU5Il-9w4-txT6GTcQcc8I",
  authDomain: "unbound-tools.firebaseapp.com",
  databaseURL: "https://unbound-tools-default-rtdb.firebaseio.com",
  projectId: "unbound-tools",
  storageBucket: "unbound-tools.appspot.com",
  messagingSenderId: "1075066427237",
  appId: "1:1075066427237:web:95520ad55834f856a9f38c",
  measurementId: "G-7CHJMZDNMC"
};

export const app = initializeApp(firebaseConfig);
export const auth = getAuth();
export const analytics = getAnalytics(app);
export const db = getFirestore(app); 
export const functions = getFunctions(app);
