import './App.css';
import logo from './img/unbound-logo-bg-trans-bc-black.png';

import React, { useRef, useState, useEffect, useContext } from 'react';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import { Link, useNavigate, Navigate, useLocation } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faBars } from '@fortawesome/free-solid-svg-icons';

// Import Home and Card components
import InputCard from './inputCard';
import HistoryCard from './historyCard';
import NullCard from './nullCard';
import Home from './Home';
import { useAuth } from './useAuth';
import { AuthContext } from './authContext.js';
import { RecordingContext } from './inputCard';
import { SwiperProvider } from './swiperContext'; // Import the provider

// Import Firebase
import { auth, app, analytics, db } from './firebase';
import { onAuthStateChanged, signOut, getAuth } from "firebase/auth";

// Import Embedding Function

// Import Firestore 
// As the number of logs/lookups grows, may want to consider ways to optimize. Reading from and writing to Firestore is billed per operation. To mitigate, could try to minimize read/write operations, like batching writes, using pagination for reads / only fetching responses that haven't been fetched before / only fetching the cards within X swipes of the current card, and using listeners to listen for changes in data instead of repeatedly reading from Firestore. But for now this should work.
import { getDocs, startAfter, serverTimestamp, doc, setDoc, getDoc, collection, onSnapshot, query, orderBy, where, limit } from "firebase/firestore";

// Import Swiper
import { register } from 'swiper/element/bundle';
import 'swiper/swiper-bundle.css';
import { Swiper, SwiperSlide } from "swiper/react";
import SwiperCore, { EffectCards, Pagination, Keyboard, History, Virtual } from "swiper";

import { get } from 'react-scroll/modules/mixins/scroller';
import { storeLog, addLookup } from './services/functions';
register();
SwiperCore.use([Pagination, Virtual]);


/**
 * The PrivateRoute component checks if the user is authenticated and either renders the children or redirects to the home page.
 *
 * @param {object} children - The child components to be rendered if the user is authenticated.
 *
 * @returns {JSX.Element} - The PrivateRoute component.
 */
function PrivateRoute({ children }) {
  let auth = useAuth();
  let location = useLocation();

  return (
    auth.user
      ? children
      : <Navigate to="/" state={{ from: location }} />
  );
}

/**
 * The Header component displays the header of the application, including the logo and menu icon.
 *
 * @param {boolean} menuVisible - A boolean indicating whether the menu is currently visible.
 * @param {function} setMenuVisible - A function that sets the visibility of the menu.
 * @param {object} menuIconRef - A reference to the menu icon element.
 *
 * @returns {JSX.Element} - The Header component.
 */
const Header = ({ menuVisible, setMenuVisible, menuIconRef }) => {
  const navigate = useNavigate();
  const handleMenuClick = (e) => {
    e.stopPropagation();
    setMenuVisible(!menuVisible);
  }

  return (
    <div style={{ pointerEvents: "none", userSelect: "none", WebkitUserDrag: "none" }} className="fixed flex top-0 w-full h-16 justify-between items-center bg-background-offwhite font-serif text-lg text-white text-bold px-4 z-99">
      <div style={{ pointerEvents: "auto" }} className="cursor-pointer mt-3" onClick={() => window.location.reload()}>
        <img src={logo} alt="Unbound Logo" style={{ width: '50px', height: 'auto' }} />
      </div>
      <div id="menu-icon" style={{ pointerEvents: "auto", transform: 'scale(1.7)' }} className="cursor-pointer mr-1" ref={menuIconRef} onClick={handleMenuClick}>
        <FontAwesomeIcon icon={faBars} size="1x" color="#333333" className="fa-fw fa-bars" />
      </div>
    </div>
  );
};

// MENU
const Menu = ({ visible, setVisible, menuIconRef }) => {
  const menuRef = useRef(null);
  const { setUser } = useContext(AuthContext);
  const navigate = useNavigate();
  const handleSignOut = async () => {
    const auth = getAuth();

    try {
      await signOut(auth);
      setVisible(false);
      setUser(null);
      console.log("User logged out successfully.");
      navigate("/");  // navigate to home after signing out
    } catch (error) {
      console.error('Error signing out', error);
    }
  };

  useEffect(() => {
    // Add click listener when component mounts (checks if the click was outside the menu and closes the menu if it was)
    const handleClickOutside = (event) => {
      if (menuRef.current && !menuRef.current.contains(event.target) && menuIconRef.current !== event.target) {
        setVisible(false);
      }
    }

    // Attach the listeners to the document
    document.addEventListener('mousedown', handleClickOutside);
    document.addEventListener('touchstart', handleClickOutside);

    // Clean up listeners when component unmounts
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
      document.removeEventListener('touchstart', handleClickOutside);
    };
  }, [setVisible, menuRef, menuIconRef]);

  return (
    <div
      ref={menuRef}
      className={`fixed top-16 right-0 w-55 bg-white rounded-sm transform transition-transform duration-200 ease-in-out z-101 
        ${visible ? 'translate-x-0' : 'translate-x-full'}`}
      style={{ zIndex: 102 }}
    >
      <ul className="flex flex-col space-y-2 p-4">
        <li>
          <Link className="text-slate-700 font-serif" to="/" onClick={() => setVisible(false)}>
            Home
          </Link>
        </li>
        <li>
          <Link className="text-slate-700 font-serif" to="/input" onClick={() => setVisible(false)}>
            New Input
          </Link>
        </li>
        <li>
          <Link className="text-slate-700 font-serif" to="/log-history" onClick={() => setVisible(false)}>
            Log History
          </Link>
        </li>
        <li>
          <Link className="text-slate-700 font-serif" to="/lookup-history" onClick={() => setVisible(false)}>
            Lookup History
          </Link>
        </li>
        <li>
          <Link className="text-slate-700 font-serif" onClick={handleSignOut}>
            Log Out
          </Link>
        </li>
      </ul>
    </div>
  );
};


// APP
function App() {
  console.log('Rendering App');
  const { user, setUser } = useContext(AuthContext);
  const [menuVisible, setMenuVisible] = useState(false);
  const [logHistory, setLogHistory] = useState([]);
  const [lookupHistory, setLookupHistory] = useState([]);
  const menuIconRef = React.useRef(null);

  useEffect(() => {
    // Disable scroll. This also means you can't pull down on mobile to refresh
    document.body.classList.add('overflow-hidden');
    return () => {
      document.body.classList.remove('overflow-hidden');
    };
  }, []);

  useEffect(() => {
    const unsubscribe = onAuthStateChanged(auth, (user) => {
      if (user) {
        // User is signed in
        console.log('User is signed in (app.js)');
        setUser(user);
      } else {
        // User is signed out
        console.log('User is signed out (app.js)');
        setUser(null);
      }
    });

    return () => unsubscribe();  // Unsubscribe to the listener when unmounting
  }, []);

  if (user === undefined) {
    // Render nothing while waiting for Firebase 
    return null;
  }
  return (
    <SwiperProvider>
      <div className="App">
        <Router>
          <div className="App min-h-screen overflow-hidden bg-background-offwhite font-serif text-center flex flex-col">
            <Menu visible={menuVisible} setVisible={setMenuVisible} menuIconRef={menuIconRef} />
            <div className="flex flex-col justify-between w-full flex-grow">
              <Routes>
                <Route path="/" element={<Home />} />
                <Route path="/input" element={
                  <PrivateRoute>
                    <Header setMenuVisible={setMenuVisible} menuIconRef={menuIconRef} />
                    <InputState setLogHistory={setLogHistory} setLookupHistory={setLookupHistory} />
                  </PrivateRoute>
                } />
                <Route path="/log-history" element={
                  <PrivateRoute>
                    <Header setMenuVisible={setMenuVisible} menuIconRef={menuIconRef} />
                    <LogHistoryState logHistory={logHistory} setLogHistory={setLogHistory} />
                  </PrivateRoute>
                } />
                <Route path="/lookup-history" element={
                  <PrivateRoute>
                    <Header setMenuVisible={setMenuVisible} menuIconRef={menuIconRef} />
                    <LookupHistoryState lookupHistory={lookupHistory} setLookupHistory={setLookupHistory} />
                  </PrivateRoute>
                } />
              </Routes>
            </div>
          </div>
        </Router>
      </div>
    </SwiperProvider>
  );
}

// function that edits an existing document in Firestore
export const editData = async (oldData, newText, isLookup, user, setLoadingAnimation) => {
  const addFunction = isLookup ? addLookup : storeLog;

  // If the text was not changed, return the old data directly
  if (oldData.text === newText) {
    return oldData;
  }

  // If the text was changed, save the new data and get the result
  const newData = await addFunction(newText, user, oldData.id, setLoadingAnimation);

  return {
    ...newData,
    prevId: oldData.id,  // reference the id of the previous document to create a linked list
  };
};

// INPUT STATE 

const InputState = ({ setLogHistory, setLookupHistory }) => {
  const navigate = useNavigate();
  const { user, setLoadingAnimation, setLoadingContext } = useContext(AuthContext);

  const handleNewLog = (newLog) => {
    console.log('handleNewLog user:', user);
    storeLog(newLog, user, null, setLoadingAnimation);
    setLogHistory(prevLogs => [newLog, ...prevLogs]);
  };

  const handleNewLookup = async (newLookup) => {
    console.log('handleNewLookup user:', user);
    // Set the loading context to indicate that a lookup operation is in progress - so that it can load the right loading animation
    setLoadingContext('LookupHistory');
    const newLookupData = await addLookup(newLookup, user, null, setLoadingAnimation);
    setLookupHistory(prevLookups => [newLookupData, ...prevLookups]);
    // Reset the loading context if needed
    setLoadingContext(null);
    navigate("/lookup-history", { state: { newLookup: newLookupData } });
  };

  const { stopRecording } = useContext(RecordingContext);
  // const { setInputValue } = useContext(ContentContext);

  const [isLogCardActive, setIsLogCardActive] = useState(false);
  const [isLookupCardActive, setIsLookupCardActive] = useState(false);
  const handleCardSwipe = (swiper) => {
    const activeIndex = swiper.activeIndex;
    if (activeIndex === 0 && isLogCardActive) {
      setIsLogCardActive(false);
    } else if (activeIndex === 1 && isLookupCardActive) {
      setIsLookupCardActive(false);
    }
    stopRecording();
    // setInputValue();
  };

  return (
    <div className="fixed top-[100px] pb-20 inset-x-0 bottom-0 h-screen flex flex-col items-center justify-end transform-gpu">
      <Swiper
        className="w-full h-full"
        effect={"cards"}
        grabCursor={true}
        loop={false}
        rewind={true}
        hashNavigation={true}
        modules={[EffectCards, Keyboard, History]}
        keyboard={{ enabled: true }}
        cardsEffect={{ slideShadows: false, rotate: true, perSlideRotate: 3, perSlideOffset: 8 }}
        onReachEnd={(swiper) => {
          handleCardSwipe(swiper);
        }}
        // onSlideChange={(swiper) => {
        //   handleCardSwipe(swiper);
        //   }}
        onSlideNextTransitionEnd={(swiper) => {
          handleCardSwipe(swiper);
        }}
        onSlidePrevTransitionEnd={(swiper) => {
          handleCardSwipe(swiper);
        }}
      >
        <SwiperSlide data-hash="log">
          <InputCard initialContent={"Log it"} onSubmit={handleNewLog} stopRecording={stopRecording} isActive={isLogCardActive} setIsActive={setIsLogCardActive} />
        </SwiperSlide>
        <SwiperSlide data-hash="lookup">
          <InputCard initialContent={"Look it up"} onSubmit={handleNewLookup} stopRecording={stopRecording} isActive={isLookupCardActive} setIsActive={setIsLookupCardActive} />
        </SwiperSlide>
      </Swiper>
    </div>
  );
};

// LOG HISTORY STATE

const LogHistoryState = ({ logHistory, setLogHistory }) => {
  const [loading, setLoading] = useState(true);
  const { user, setLoadingAnimation } = useContext(AuthContext);
  const uid = user?.uid; // get current user's UID
  const [activeSlide, setActiveSlide] = useState(0);

  // subscribe to logs collection changes in Firestore 
  const fetchLogs = async (startAfterDoc = null) => {
    console.log("Fetching lookups after doc:", startAfterDoc);
    const environment = window.location.hostname === "localhost" ? "local" : "production";
    let q = query(
      collection(db, "logs"),
      where("uid", "==", uid),
      where("deleted", "==", false),
      where("environment", "==", environment),
      orderBy("timestamp", "desc"),
      // limit(10)
    );

    let logs = [];
    const unsubscribe = onSnapshot(q, (querySnapshot) => {
      querySnapshot.forEach((doc) => {
        const docData = doc.data();
        const log = {
          text: docData.text,
          timestamp: docData.timestamp,
          id: doc.id,
          highlights: [
            ...(docData.locations || []),
            ...(docData.events || []),
            ...(docData.persons || []),
            ...(docData.dates || []),
            ...(docData.organizations || [])
          ] // Combine all highlight arrays and handle null/undefined  
        }
        logs.push(log); // push the entire log object
        console.log(logs);
      });
      setLogHistory(logs);
      setLoading(false);
    });
  };

  // Fetch first batch of logs when the component mounts
  useEffect(() => {
    fetchLogs();
  }, []);

  const swiperRef = useRef(null);
  const jumpSlides = () => {
    console.log(swiperRef.current)
    if (swiperRef.current && swiperRef.current.swiper) {
      const totalSlides = logHistory.length;
      let jumpCount;

      if (totalSlides < 30) {
        jumpCount = Math.round(totalSlides * 0.2);
      } else if (totalSlides < 45) {
        jumpCount = Math.round(totalSlides * 0.15);
      } else {
        jumpCount = Math.round(totalSlides * 0.1);
      }

      let jumpIndex;
      if (swiperRef.current.swiper.activeIndex === totalSlides - 1) {
        // If we're on the last slide, jump to the first slide
        jumpIndex = 0;
      } else {
        jumpIndex = swiperRef.current.swiper.activeIndex + jumpCount;
        // Ensure we don't exceed total slides.
        jumpIndex = Math.min(jumpIndex, totalSlides - 1);
      }

      swiperRef.current.swiper.slideTo(jumpIndex);
    }
  };

  const [shouldShowNullCard, setShouldShowNullCard] = useState(false);

  // only show null card after a 0.5 second delay
  useEffect(() => {
    let timer;
    if (logHistory.length === 0 && !loading) {
      timer = setTimeout(() => {
        setShouldShowNullCard(true);
      }, 500);  // 500ms delay
    } else {
      setShouldShowNullCard(false);
    }
    return () => clearTimeout(timer);  // Clear the timer if the component is unmounted
  }, [logHistory, loading]);

  return loading ? null : (
    shouldShowNullCard ?
      <div className="fixed top-[100px] pb-20 inset-x-0 bottom-0 h-screen flex flex-col items-center justify-end transform-gpu">
        <NullCard nullContent={"You don't have any Logs yet!"} nullButton={"Make your first Log"} />
      </div>
      :
      <div className="fixed top-[100px] pb-20 inset-x-0 bottom-0 h-screen flex flex-col items-center justify-end transform-gpu">
        <Swiper
          className="h-full w-full"
          ref={swiperRef}
          // effect={"cards"}
          grabCursor={true}
          // loop={true}
          rewind={true}
          modules={[EffectCards, Pagination, Keyboard, Virtual]}
          pagination={logHistory.length >= 20 ? { type: 'fraction' } : false}
          keyboard={{ enabled: true }}
          // cardsEffect={{ slideShadows: false, rotate: true, perSlideRotate: 2, perSlideOffset: 8 }}
          // virtual={{ slides: logHistory, addSlidesBefore: 4, addSlidesAfter: 4 }}
          virtual
          observer={true}
          observeParents={true}
          slidesPerView={1}
          onSlideChange={(swiper) => {
            setActiveSlide(swiper.activeIndex);
          }}
        // TODO: active slide different color than inactive slides using https://swiperjs.com/react#swiperslide-render-function
        >
          {logHistory.map((log, index) => (
            <SwiperSlide key={log.id} virtualIndex={index}>
              <HistoryCard
                log={log}
                editData={editData}
                setLogHistory={setLogHistory}
                setLoadingAnimation={setLoadingAnimation}
              />
            </SwiperSlide>
          ))}
          {logHistory.length >= 20 && (
            <button
              onClick={jumpSlides}
              className='swiper-pagination sm:swiper-pagination-sm border-slate-300 rounded-md py-1 px-2 -mb-1 shadow-slate-700/50 shadow-sm z-200'
              style={{
                position: 'absolute',
                transform: 'translateX(40px)',
                // bottom: '16%'
              }}
            >
              Skip
            </button>)}
        </Swiper>
      </div>
  );
};

// LOOKUP HISTORY STATE

const LookupHistoryState = ({ lookupHistory, setLookupHistory }) => {
  const [loading, setLoading] = useState(true);
  const location = useLocation();
  const [responseDataCache, setResponseDataCache] = useState({});
  const newLookupFromState = location.state?.newLookup;
  const { user, setLoadingAnimation } = useContext(AuthContext);
  const uid = user?.uid; // get current user's UID
  const [activeSlide, setActiveSlide] = useState(0);

  const fetchAndCacheResponseData = async (responseId) => {
    let responseData = {};
    console.log(`Fetching for responseId: ${responseId}`);
    if (!responseDataCache[responseId]) {
      const responseDoc = await getDoc(doc(db, 'responses', responseId));
      if (responseDoc.exists) {
        responseData = responseDoc.data();
        setResponseDataCache(prevCache => ({
          ...prevCache,
          [responseId]: responseData,
        }));
      }
    } else {
      responseData = responseDataCache[responseId];
    }
    // console.log(`Fetched responseData for ${responseId}:`, responseData);
    return responseData;
  };

  // subscribe to lookups collection changes in Firestore 
  const fetchLookups = async (startAfterDoc = null) => {
    console.log("Fetching lookups after doc:", startAfterDoc);
    const environment = window.location.hostname === "localhost" ? "local" : "production";
    let q = query(
      collection(db, "lookups"),
      where("uid", "==", uid),
      where("deleted", "==", false),
      where("environment", "==", environment),
      orderBy("timestamp", "desc"),
      // limit(10)
    );

    const querySnapshot = await getDocs(q);
    let lookups = [];

    // construct lookup object with response text and candidate array
    await Promise.all(querySnapshot.docs.map(async (doc) => {
      const docData = doc.data();

      // Fetch and cache the response data and use it directly
      const responseData = await fetchAndCacheResponseData(docData.responseId);

      lookups.push({
        text: docData.text,
        responseText: responseData ? responseData.text : "", // Check if responseData exists
        candidateArray: responseData ? responseData.candidateArray : [],
        timestamp: docData.timestamp,
        id: doc.id,
        responseId: docData.responseId,
        highlights: [
          ...(docData.locations || []),
          ...(docData.events || []),
          ...(docData.persons || []),
          ...(docData.dates || []),
          ...(docData.organizations || [])
        ] // Combine all highlight arrays and handle null/undefined
      });
    }));
    setLookupHistory(prevLookups => [...prevLookups, ...lookups]);
    setLoading(false);
  };

  useEffect(() => {
    fetchLookups();
  }, []);

  const handleSlideChange = async (swiper) => {
    const activeIndex = swiper.activeIndex;

    // Pre-fetch the responseData for the next 10 lookups
    for (let i = activeIndex + 1; i <= activeIndex + 10; i++) {
      if (lookupHistory[i]) {
        await fetchAndCacheResponseData(lookupHistory[i].responseId);
      }
    }
  };

  // Add new lookup from state to the lookup history.
  useEffect(() => {
    if (newLookupFromState) {
      setLookupHistory(prev => [newLookupFromState, ...prev]);
    }
  }, [newLookupFromState, setLookupHistory]);

  const swiperRef = useRef(null);
  const jumpSlides = () => {
    console.log(swiperRef.current)
    if (swiperRef.current) {
      const totalSlides = lookupHistory.length;
      let jumpCount;

      if (totalSlides < 30) {
        jumpCount = Math.round(totalSlides * 0.2);
      } else if (totalSlides < 45) {
        jumpCount = Math.round(totalSlides * 0.15);
      } else {
        jumpCount = Math.round(totalSlides * 0.1);
      }

      let jumpIndex;
      if (swiperRef.current.activeIndex === totalSlides - 1) {
        // If we're on the last slide, jump to the first slide
        jumpIndex = 0;
      } else {
        jumpIndex = swiperRef.current.activeIndex + jumpCount;
        // Ensure we don't exceed total slides.
        jumpIndex = Math.min(jumpIndex, totalSlides - 1);
      }

      swiperRef.current.slideTo(jumpIndex);
    }
  };

  const [shouldShowNullCard, setShouldShowNullCard] = useState(false);

  // only show null card after a 0.5 second delay
  useEffect(() => {
    let timer;
    if (lookupHistory.length === 0 && !loading) {
      timer = setTimeout(() => {
        setShouldShowNullCard(true);
      }, 500);  // 500ms delay
    } else {
      setShouldShowNullCard(false);
    }
    return () => clearTimeout(timer);  // Clear the timer if the component is unmounted
  }, [lookupHistory, loading]);

  return loading ? null : (
    shouldShowNullCard ?
      <div className="fixed top-[100px] pb-20 inset-x-0 bottom-0 h-screen flex flex-col items-center justify-end transform-gpu">
        <NullCard nullContent={"You don't have any Lookups yet!"} nullButton={"Make your first Lookup"} />
      </div>
      :
      <div className="fixed top-[100px] pb-20 inset-x-0 bottom-0 h-screen flex flex-col items-center justify-end transform-gpu">
        <Swiper
          className="h-full w-full"
          ref={swiperRef}
          // effect={"cards"}
          grabCursor={true}
          // loop={true}
          rewind={true}
          modules={[EffectCards, Pagination, Keyboard, Virtual]}
          pagination={lookupHistory.length >= 20 ? { type: 'fraction' } : false}
          keyboard={{ enabled: true }}
          // cardsEffect={{ slideShadows: false, rotate: true, perSlideRotate: 2, perSlideOffset: 8 }}
          // virtual={{ slides: logHistory, addSlidesBefore: 4, addSlidesAfter: 4 }}
          virtual
          observer={true}
          observeParents={true}
          slidesPerView={1}
          onSwiper={(swiper) => {
            swiperRef.current = swiper;
            handleSlideChange(swiper);
          }}
          onSlideChange={(swiper) => {
            setActiveSlide(swiper.activeIndex);
          }}
        // TODO: active slide different color than inactive slides using https://swiperjs.com/react#swiperslide-render-function
        >
          {lookupHistory.map((lookup, index) => (
            <SwiperSlide key={lookup.id} virtualIndex={index}>
              <HistoryCard
                lookup={lookup}
                editData={editData}
                setLookupHistory={setLookupHistory}
                setLoadingAnimation={setLoadingAnimation}
              />
            </SwiperSlide>
          ))}
          {lookupHistory.length >= 20 && (
            <button
              onClick={jumpSlides}
              className='swiper-pagination sm:swiper-pagination-sm border-slate-300 rounded-md py-1 px-2 -mb-1 shadow-slate-700/50 shadow-sm z-200'
              style={{
                position: 'absolute',
                transform: 'translateX(40px)',
                // bottom: '16%'
              }}
            >
              Skip
            </button>)}
        </Swiper>
      </div>
  );
}

export default App;