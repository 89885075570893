// NULL CARD COMPONENT
// for when a user has no previously saved logs or lookups

import React, { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';

export default function NullCard({ nullContent, nullButton }) {
  // console.log('xyz: ', var);
  const navigate = useNavigate();

  useEffect(() => {
    // Disable body scroll when component mounts
    document.body.style.overflow = 'hidden';

    // Enable body scroll when component unmounts
    return () => {
      document.body.style.overflow = '';
    };
  }, []); // Empty dependency array to ensure this runs on mount and cleanup on unmount

  return (
    <div 
      className={`fixed top-[30px] pb-20 p-4 border-4 bg-white border-[#1EAEE1] text-slate-700 font-serif font-bold 
        rounded-xl m-auto w-4/5 sm:w-2/3 md:w-1/2 lg:w-1/3 h-4/5 flex flex-col justify-evenly items-center z-50`}
      style={{ height: '65vh' }}
    >
        <div className="mx-8 font-bold text-xl">
          {nullContent}
        </div>
        <button
          className="px-4 py-3 bg-card text-white font-serif font-bold rounded-lg shadow-slate-700/25 shadow-md hover:shadow-slate-700/40 hover:shadow-sm flex"
          // onClick={() => navigate('/input')}
          onClick={() => navigate(nullButton.toLowerCase().includes('look') ? '/input#lookup' : '/input#log')} // I tried to get this to work for way too long. This isn't an actual path so it renders whitescreen. Tried a number of ways to redirect to /input and initiate a swipe event when /input/lookup is called, but (omg the rest was an autocomplete! hahaha) // couldn't get it to work I think it's because the swipe event is called in the useEffect of the Input component, and I couldn't figure out how to call that useEffect from here. I tried to use a ref to the Input component, but couldn't get that to work either. I think I need to learn more about refs.
        >
          {nullButton}
        </button>
    </div>
  );
}