import { httpsCallable } from "firebase/functions";
import { functions, db } from "../firebase";
import { serverTimestamp, setDoc, doc, collection } from "firebase/firestore";
import { queryVector, storeLogVector } from "./weaviate";
import { getResponseText } from "./openai";

export const getEmbedding = httpsCallable(functions, 'get_embedding');
const analyzeEntities = httpsCallable(functions, 'analyzeEntities');

export async function storeLog(logText, user, prevId, setLoadingAnimation) {
  // setLoadingAnimation(true);

  try {
    // Kickoff Embedding Retrieval
    let embedding = getEmbedding({ text: logText });

    // Store the log in firestore.
    let docRef = doc(collection(db, "logs"));

    let entities = {};
    try {
      const result = await analyzeEntities({ text: logText });
      entities = result.data;
      console.log('Entities received from server: ', entities);
    } catch (error) {
      console.error('Error:', error);
    }

    // Store the new document in firestore:
    await setDoc(
      docRef,
      {
        text: logText, // Add the log text
        timestamp: serverTimestamp(), // Add the timestamp
        timestampUnixMs: Date.now(), // Add the timestamp in unix milliseconds
        uid: user.uid, // Add the uid of the user
        ...(prevId ? { prevId: prevId } : {}), // Add prevId only if it is not defined.
        environment: window.location.hostname === "localhost" ? "local" : "production", // Add the environment
        deleted: false, // Add deleted
        ...entities
      }
    );
    // setLoadingAnimation(false);

    // Store the log vector in Weaviate

    // Get the vector from the embedding, convert to array
    // const vector = JSON.parse((await embedding).data.encoding);
    const vector = JSON.parse((await embedding).data.encoding);
    await storeLogVector(
      vector,
      logText,
      user.uid,
      docRef.id,
      window.location.hostname === "localhost" ? "local" : "production",
      false
    );

  } catch (e) {
    console.error("Error adding log: ", e);
  }
};

export async function addLookup(lookupText, user, prevId, setLoadingAnimation) {
  setLoadingAnimation(true);

  let newLookupRef, newResponseRef;
  try {
    // Kickoff Embedding Retrieval
    let embedding = getEmbedding({ text: lookupText });

    // Create pointers to a look up and response document.
    let newLookupRef = doc(collection(db, "lookups"));
    let newResponseRef = doc(collection(db, "responses"));

    let entities = {};
    try {
      const result = await analyzeEntities({ text: lookupText });
      entities = result.data;
      console.log('Entities received from server: ', entities);
    } catch (error) {
      console.error('Error:', error);
    }

    // Store the Lookup in firestore.
    await setDoc(
      newLookupRef,
      {
        text: lookupText, // Add the lookup text
        timestamp: serverTimestamp(), // Add the timestamp
        timestampUnixMs: Date.now(), // Add the timestamp in unix milliseconds
        responseId: newResponseRef.id, // Add the response id
        uid: user.uid, // Add the uid of the user
        ...(prevId ? { prevId: prevId } : {}), // Add prevId only if it is not undefined.
        environment: window.location.hostname === "localhost" ? "local" : "production", // Add the environment
        deleted: false, // Add deleted
        ...entities
      }
    )

    // Retrieve relevant documents:
    let vector = JSON.parse((await embedding).data.encoding);
    let response = await queryVector(vector, user.uid);
    const entries = response.data.Get.Entry;
    // console.log("Raw entries:", JSON.stringify(entries, null, 2));

    // Transform the entries array
    const transformedEntries = entries.map(entry => {
      // console.log("Processing entry:", entry);
      // console.log("Accessed distance:", entry._additional.distance);
      return {
        candidate: entry.text,
        relevance: entry._additional.distance,
        logId: entry.docid,
        uid: entry.uid
      };
    });
    console.log(transformedEntries)

    const responseText = (await getResponseText(lookupText, transformedEntries)).choices[0].message.content;
    console.log(responseText);

    // console.log(responseText.choices[0].message.content);/
    // console.log(responseText)

    // Store the response in firestore.
    await setDoc(
      newResponseRef,
      {
        text: responseText, // Add the response text
        lookupId: newLookupRef.id, // Add the lookup id
        timestamp: serverTimestamp(), // Add the timestamp
        environment: window.location.hostname === "localhost" ? "local" : "production", // Add the environment
        candidateArray: transformedEntries
      }
    )
    console.log("Candidates: ", transformedEntries);
  } catch (e) {
    console.error("Error adding lookup: ", e);
  }
  setLoadingAnimation(false);
}