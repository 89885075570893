import React, { useEffect, useState, useRef, useContext } from 'react';
import { useNavigate } from 'react-router-dom';
import { signInWithPopup, GoogleAuthProvider } from "firebase/auth";
import { auth } from './firebase';

import { AuthContext, AuthProvider } from './authContext.js';

// IMAGES
import hero from './img/hero-1.jpg'; 
import heroPop from './img/hero-1-pop.jpg'; 
import heroSecondary from './img/hero-1-zoomout-auto.jpg'; 
import placeholderImg from './img/base64-pixel.png';
import logo from './img/unbound-logo-bg-trans-bc-white.png'; 
import newLogImage from './img/new-log.png'
import newLookupImage from './img/new-lookup.png'
import lookupImage from './img/lookup.png'


function Home() {
  const [isTextFixed, setIsTextFixed] = useState(false);
  const [fade, setFade] = useState(1);

  useEffect(() => {
    const handleScroll = () => {
      const scrollPos = window.scrollY || document.documentElement.scrollTop;
      //  text becomes sticky when the scroll position is greater than 1.5 screen heights and stops being sticky after 3 screen heights. Hence, the sticky state is maintained for 3 - 1.5 = 1.5 screen heights, which means the total height occupied by your sticky text and its space should be about 1.5 screen heights + the height of the sticky text
      if (scrollPos > window.innerHeight * 1.5 && scrollPos < window.innerHeight * 3) { // How far down the page should the text be fixed?
        setIsTextFixed(true);
        const fadeStart = window.innerHeight * 2; // When should fade-out start?
        if (scrollPos > fadeStart) {
          setFade(1 - (scrollPos - fadeStart) / (window.innerHeight / 2.3)); // creates a linear fade-out effect that starts when the scroll position is at fadeStart and ends half a screen height later.
        } else {
          setFade(1);
        }
      } else {
        setIsTextFixed(false);
      }
    };

    window.addEventListener('scroll', handleScroll);
    return () => window.removeEventListener('scroll', handleScroll);
  }, []);

  return (
    <div className="flex flex-col min-h-screen items-center justify-center bg-[#00182E] font-serif text-xl md:text-2xl text-white">
      <div className="relative w-full">
        <Header />
        <Hero />
      </div>
      <CenteredText isFixed={isTextFixed} fade={fade} />
      {isTextFixed && <div style={{ height: '100vh' }} />} {/* Spacer to account for stickiness */}
      {/* {!isTextFixed && <div style={{ height: '50vh' }} />} Spacer to help avoid jumping while transitioning to/from stickiness */}
      <ZigzagSection number={1} 
        text={
          <>
            <p className="" style={{ marginBottom: '1rem' }}>When you have a thought, get it out of your head!</p>
            <p className="font-bold">Log it and forget it.</p>
          </>
        }
        imageSrc={newLogImage} />
      <ZigzagSection number={2} 
        text={
          <>
            <p className="" style={{ marginBottom: '1rem' }}>Look up things you've logged whenever you're ready for them.</p>
            <p className="">Nothing special – just talk naturally.</p>
          </>
        }
        imageSrc={newLookupImage} />
      <ZigzagSection number={3} 
        text={
          <>
            <p className="">Unbound leverages AI to put everything in context, returning exactly what you were looking for.</p>
          </>
        }
        imageSrc={lookupImage} />
      <Footer />
    </div>
  );
}

// HEADER 
function Header() {
  const navigate = useNavigate();
  const { user, auth } = useContext(AuthContext);
  const signIn = () => {
    const provider = new GoogleAuthProvider();
    signInWithPopup(auth, provider)
      .then((result) => {
        const user = result.user;
        console.log('User is signed in (home.js)');
        console.log(user);
        navigate("/input");
      })
      .catch((error) => {
        console.log('Failed to sign in (home.js)');
        console.log(error);
      });
  };

  return (
    <div className="absolute top-0 left-0 w-full h-16 flex items-center justify-between px-4 bg-transparent z-10">
      <div className="cursor-pointer mt-2" onClick={() => navigate('/')}>
        <img src={logo} alt="Unbound Logo" style={{ width: '50px', height: 'auto' }} />
      </div>
      <button onClick={signIn} className="mt-2 mr-1 py-1 px-3 border border-white rounded-md text-lg text-white bg-transparent">Log in</button>
    </div>
  );
}

// HERO
function Hero() {
  const [scrollState, setScrollState] = useState({
    offset: 0,
    crossedPopThreshold: false,
    crossedTransitionThreshold: false
  });
  const screenHeight = window.innerHeight;
  const currentImage = scrollState.crossedPopThreshold ? 
                       (scrollState.crossedTransitionThreshold ? heroSecondary : heroPop) : hero;

  useEffect(() => {
    const handleScroll = () => {
      const newOffset = window.pageYOffset;
      const newState = {
        offset: newOffset,
        crossedPopThreshold: scrollState.crossedPopThreshold || newOffset > screenHeight * 0.25,
        crossedTransitionThreshold: scrollState.crossedTransitionThreshold || newOffset > screenHeight * 2.5
      };
      setScrollState(newState);
    };

    window.addEventListener('scroll', handleScroll);
    return () => window.removeEventListener('scroll', handleScroll);
  }, [screenHeight, scrollState.crossedPopThreshold, scrollState.crossedTransitionThreshold]);

  return (
    <>
      <div className="relative w-full h-screen flex items-end justify-center"> 
        <img 
          className="w-full h-full object-cover absolute top-0 left-0" 
          src={currentImage} 
          alt="Unbound Hero Image"
          style={{
            // transform: `translateY(${scrollState.offset * 0.5}px)`, // parallax effect
            filter: `blur(${scrollState.offset * 0.03}px)`
          }}
        />
        <div 
          className="absolute w-full h-full z-11"
          style={{
            top: '-20%', 
            height: '250%', 
            background: `linear-gradient(0deg, rgba(0, 24, 46, ${Math.max(Math.min((scrollState.offset - screenHeight * 1/3) / (screenHeight * 0.75), 2), 0)}), rgba(0, 24, 46, ${Math.max(Math.min((scrollState.offset - screenHeight * 1/3) / (screenHeight * 0.75), 2.5), 0)}))`
          }}
        ></div>
        <div className="mb-40 text-5xl italic text-white z-10"> 
          Unbound
        </div>
      </div>
      <div style={{height: "100vh"}} /> {/* Spacer to adjust for parallax */}
    </>
  );
}

// CENTERED TEXT
function CenteredText({ isFixed, fade }) {
  return (
    <div className={`w-full h-screen flex flex-col items-center justify-center text-white font-serif ${isFixed ? 'fixed' : ''}`} style={{ top: '50%', transform: 'translateY(-50%)', opacity: fade }}>
      <div className="w-5/6 sm:w-3/5 mb-12 text-4xl sm:text-5xl font-semibold text-center text-white font-serif">
        <p>Your head is for having ideas, not holding them.</p>
      </div>
      <div className="w-5/6 sm:w-3/5 text-2xl sm:text-3xl text-center text-white font-serif">
        <p>Get ideas out of your head, as fast as you can talk.</p>
        <p>Unbound keeps track of everything for you... just ask.</p>
      </div>
    </div>
  );
}


// ZIGZAG 
function ZigzagSection({ number, text, imageSrc }) {
  return (
    <div className={`flex flex-col ${number % 2 === 0 ? 'sm:flex-row-reverse' : 'sm:flex-row'} justify-around items-center py-12 mx-8`}>
      <div className="w-2/3 sm:w-1/3 mb-8 sm:mb-0">
        <p>{text}</p>
      </div>
      <div className="w-2/3 sm:w-1/3">
        <div className="w-full rounded-md overflow-hidden">
          <img className="w-full object-cover" src={imageSrc} alt="" />
        </div>
      </div>
    </div>
  );
}


// FOOTER
function Footer() {
  return (
    <footer className="w-full mt-20 sm:mt-44 p-8 bg-[#00182E] text-sm text-white flex justify-between items-center">
      <a href="mailto:unboundtools@gmail.com" className="text-white">Get in touch</a>
      © 2023 Unbound. All rights reserved.
    </footer>
  );
}

export default Home;