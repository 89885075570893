// INPUT CARD COMPONENT

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faMicrophone, faKeyboard, faArrowUp, faXmark } from '@fortawesome/free-solid-svg-icons';
import React, { createContext, useRef, useState, useEffect, useContext } from 'react';
import { AuthContext } from './authContext.js';
import SwiperCore, { FreeMode } from 'swiper';
import { Swiper, SwiperSlide } from 'swiper/react';
import 'swiper/swiper-bundle.css';
import { SwiperContext } from './swiperContext.js'; // Import the context

SwiperCore.use([FreeMode]);

export const RecordingContext = createContext({
  stopRecording: () => { },
});

export default function InputCard({ initialContent, onSubmit, isActive, setIsActive }) {
  const { swiperConfig, setSwiperConfig } = useContext(SwiperContext);
  const [inputValue, setInputValue] = useState("");
  // const inputContentLabel = initialContent.toLowerCase().includes('look') ? 'look up' : 'log';
  const [isRecording, setIsRecording] = useState(false);
  const recognitionRef = useRef(null);
  const stopTimeoutRef = useRef(null);
  const textareaRef = useRef(null);
  const finalTranscriptRef = useRef("");  // keep this outside startRecording function so it doesn't get reset each time recording (re)starts

  // Array of placeholder texts
  const logPlaceholders = [
    'What\'s on your mind?',
    'Anything you need to remember?',
    'What do you want to log?',
  ];

  const lookupPlaceholders = [
    'Ask about anything you\'ve ever logged.',
    'What do you want to look up?',
  ];

  const logSuggestions = [
    'I need to remember',
    'When I was talking to',
    'I had a thought',
    'Today I saw',
    'I just realized',
  ];

  const lookupSuggestions = [
    'Who was it that',
    'Where was the place that',
    'How long has it been since',
    'What was the name of the',
  ];

  // State variable for the current placeholder text. Net effect of the math is to generate a random integer between 0 and `lookupPlaceholders.length - 1`, inclusive. This integer can then be used as an index to select a random element from the array.
  const [logPlaceholder, setLogPlaceholder] = useState(logPlaceholders[Math.floor(Math.random() * logPlaceholders.length)]);
  const [lookupPlaceholder, setLookupPlaceholder] = useState(lookupPlaceholders[Math.floor(Math.random() * lookupPlaceholders.length)]);

  const isMobile = /iPhone|iPad|iPod|Android/i.test(navigator.userAgent);

  const startRecording = () => {
    finalTranscriptRef.current = inputValue + ' '; // Capture the current inputValue so that recording will be appended if there's already something in the textarea
    setIsActive(true);
    if (recognitionRef.current) {
      recognitionRef.current.stop();
      recognitionRef.current = null;
    }

    if ('webkitSpeechRecognition' in window) {
      let voiceRecognition = new window.webkitSpeechRecognition();
      voiceRecognition.interimResults = true;
      voiceRecognition.continuous = true;

      voiceRecognition.onstart = () => {
        setIsRecording(true);
      };

      voiceRecognition.onend = () => {
        setIsRecording(false);
      };

      voiceRecognition.onresult = (event) => {
        // Clear previous timeout
        clearTimeout(stopTimeoutRef.current);

        let interimTranscript = "";

        for (let i = event.resultIndex; i < event.results.length; ++i) {
          if (event.results[i].isFinal && event.results[i][0].confidence !== 0) { // treat confidence of 0 as meaning the result is not final
            if (isMobile) { // mobile devices return sentences as final. Concat-ing can lead to duplicate words
              finalTranscriptRef.current = finalTranscriptRef.current + event.results[i][0].transcript;
            } else { // desktop devices return words as final. Concat-ing is preferred
              finalTranscriptRef.current += finalTranscriptRef.current + event.results[i][0].transcript;
            }
          } else {
            if (isMobile) { // mobile devices return sentences as final. Concat-ing can lead to duplicate words
              interimTranscript = event.results[i][0].transcript;
            } else { // desktop devices return words as final. Concat-ing is preferred
              interimTranscript += event.results[i][0].transcript;
            }
          }
        }

        // Start new timeout to stop recording if no more onresult events are triggered within 5 seconds
        stopTimeoutRef.current = setTimeout(() => {
          if (recognitionRef.current) {
            recognitionRef.current.stop(); // This will trigger onend event
          }
        }, 5000);

        setInputValue(finalTranscriptRef.current + ' ' + interimTranscript);
      };

      voiceRecognition.start();
      recognitionRef.current = voiceRecognition;
    } else {
      console.log("Your Browser does not support voice recognition");
    }
  };

  const stopRecording = () => {
    if (recognitionRef.current) {
      recognitionRef.current.stop(); // This will trigger onend event
      recognitionRef.current = null;
    }
    finalTranscriptRef.current = "";
    clearTimeout(stopTimeoutRef.current);  // clear the timeout set in startRecording
  };

  const handleMicClick = () => {
    console.log("Mic button clicked at:", new Date().toISOString());
    if (isRecording) {
      stopRecording();
    } else {
      startRecording();
    }
  };

  const handleInputChange = (e) => {
    const newValue = e.target.value;
    finalTranscriptRef.current = newValue; // update finalTranscriptRef so that if there's another recording, it's appended to what was just typed, rather than writing over it
    setInputValue(newValue);
  };

  const handleSubmitClick = (value) => {
    if (textareaRef.current) {
      textareaRef.current.blur();
    }
    stopRecording();
    onSubmit(value);
    setInputValue(""); // Clear any current input
    finalTranscriptRef.current = "";
    setIsActive(false);
  };


  // I want to be able to submit a new log/lookup by pressing CMD/CTRL + Enter
  const handleKeyDown = (e) => {
    if ((e.key === 'Enter' && e.metaKey) || (e.key === 'Enter' && e.ctrlKey)) {
      e.preventDefault();  // prevent the Enter key from submitting the form
      handleSubmitClick(e.target.value);
    }
  };

  // end recording and discard inputs if user hits escape key
  useEffect(() => {
    // The function to call when Escape key is pressed
    const handleEscapeKeyDown = (event) => {
      if (event.key === 'Escape' && isActive) {
        setIsActive(false);
        setInputValue(""); // Clear any current input
        finalTranscriptRef.current = "";
        stopRecording(); // Stop any ongoing recording
      }
    };

    // Add event listener
    document.addEventListener('keydown', handleEscapeKeyDown);

    // Remove event listener on cleanup
    return () => {
      document.removeEventListener('keydown', handleEscapeKeyDown);
    };
  }, [isActive]);  // Add isActive to the dependency array so that the effect reruns whenever isActive changes

  const [clickCount, setClickCount] = useState(0);
  const handleClick = (e) => {
    setClickCount(prevCount => prevCount + 1);
    setTimeout(() => {
      setClickCount(0);
    }, 250);

    if (clickCount >= 1) {
      e.target.select();
    }
  };

  const swiperRef = useRef(null);

  useEffect(() => {
    if (swiperRef.current) {
      const swiperEl = swiperRef.current.el;

      const stopPropagation = (e) => e.stopPropagation();

      swiperEl.addEventListener('touchstart', stopPropagation);
      swiperEl.addEventListener('touchmove', stopPropagation);

      // Cleanup the event listeners on unmount
      return () => {
        swiperEl.removeEventListener('touchstart', stopPropagation);
        swiperEl.removeEventListener('touchmove', stopPropagation);
      };
    }
  }, []);

  const isLookupCard = initialContent.toLowerCase().includes('look');

  return (
    <div
      className={`p-4 shadow-slate-700/20 shadow-md 
          text-white font-serif rounded-lg m-auto w-4/5 sm:w-2/3 md:w-1/2 lg:w-1/3 
          flex flex-col justify-center items-center z-50 relative`}
      style={{
        height: '65vh',
        background: `
          radial-gradient(circle at 30% 30%, rgba(255, 255, 255, 0.5), transparent),
          radial-gradient(circle at center, #1FADE1 30%, #2fc4fa 100%),
          linear-gradient(#2fc4fa, #2fc4fa) content-box
        `,
        boxShadow: `
          0 10px 15px rgba(0, 0, 0, 0.2), 
          0 5px 10px rgba(0, 0, 0, 0.15), 
          inset 0 6px 12px rgba(255, 255, 255, 0.8),
          inset 0 -5px 15px rgba(0, 0, 0, 0.6)
        `,
        // border: 'double 1px transparent',
        borderRadius: '20px',
        backgroundImage: `
          linear-gradient(rgba(255, 255, 255, 0.1), rgba(255, 255, 255, 0.1)),
          linear-gradient(#2fc4fa, #1FADE1),
          radial-gradient(circle at center, #1FADE1, #2fc4fa)
        `
      }}
    >
      {!isActive && <div className="font-bold text-3xl mt-20">{initialContent}</div>}
      {isActive ? (
        <div className="w-5/6 h-5/6 -mt-12 flex flex-col justify-center items-center">
          <div className="relative flex flex-col w-full h-full">
            <textarea
              className="w-full text-slate-800 font-normal text-md rounded-md p-3 resize-none outline-none placeholder-slate-400"
              style={{ touchAction: 'pan-y', flex: '1 0 auto' }}
              ref={textareaRef}
              value={inputValue}
              onChange={handleInputChange}
              onKeyDown={handleKeyDown} // so that CMD/CTRL + Enter can submit the form
              placeholder={inputValue.length === 0 ? (isLookupCard ? lookupPlaceholder : logPlaceholder) : ''}
              onClick={handleClick}
              onContextMenu={e => e.preventDefault()} // prevent right-click menu from showing
            />
          </div>
          <div className="flex justify-center items-center text-center w-full mt-5">
            <Swiper
              ref={swiperRef}
              {...swiperConfig}
              spaceBetween={10}
              freeMode={true}
              nested={true}
              slidesPerView={1.7}
              loop={true}
              // grabCursor={true}
              style={{ height: '55px' }}
            >
              {isLookupCard
                ? lookupSuggestions.map((suggestion, index) => (
                  <SwiperSlide key={index}
                    className="flex justify-center items-center text-center w-full text-slate-400 font-normal font-serif text-md italic rounded-lg px-2 py-1 resize-none outline-none bg-white shadow-slate-700/20 shadow-md"
                    onClick={() => {
                      setInputValue(suggestion + ' ');
                      if (textareaRef.current) {
                        textareaRef.current.focus();
                      }
                    }}>
                    <div className="flex justify-center items-center h-full">
                      {suggestion}...
                    </div>
                  </SwiperSlide>
                ))
                : logSuggestions.map((suggestion, index) => (
                  <SwiperSlide key={index}
                    className="flex justify-center items-center text-center w-full text-slate-400 font-normal font-serif text-md italic rounded-lg px-2 py-1 resize-none outline-none bg-white shadow-slate-700/20 shadow-md"
                    onClick={() => {
                      setInputValue(suggestion + ' ');
                      if (textareaRef.current) {
                        textareaRef.current.focus();
                      }
                    }}>
                    <div className="flex justify-center items-center h-full">
                      {suggestion}...
                    </div>
                  </SwiperSlide>
                ))
              }
            </Swiper>
          </div>
          <div className="flex items-between space-x-4 mt-4 -mb-6">
            <button
              className={`flex items-center justify-center mt-2 rounded-full `}
              style={{
                width: '38px',
                height: '38px',
                fontSize: '17px',
                transform: 'translateY(12px)',
                boxShadow: `
                0 3px 5px rgba(0, 0, 0, 0.1), 
                0 2px 3px rgba(0, 0, 0, 0.06), 
                inset 0 1px 2px rgba(255, 255, 255, 0.5),
                inset 0 -1px 3px rgba(0, 0, 0, 0.4)
              `,
                background: `
                radial-gradient(circle at center, rgba(255, 255, 255, 0.3), transparent),
                linear-gradient(45deg, #2fc4fa, #1FADE1)
              `,
              }}
              onClick={() => {
                if (isRecording) {
                  stopRecording();
                }
                if (textareaRef.current) {
                  textareaRef.current.focus();
                }
              }}
            >
              <FontAwesomeIcon icon={faKeyboard} size={"1x"} color={"white"} />
            </button>
            <button
              className={`flex items-center justify-center mt-2 rounded-full ${isRecording ? 'animate-pulse' : ''}`}
              style={{
                width: '64px',
                height: '64px',
                fontSize: '30px',
                boxShadow: `
                0 3px 5px rgba(0, 0, 0, 0.1), 
                0 2px 3px rgba(0, 0, 0, 0.06), 
                inset 0 1px 2px rgba(255, 255, 255, 0.5),
                inset 0 -1px 3px rgba(0, 0, 0, 0.4)
              `,
                background: `
                radial-gradient(circle at center, rgba(255, 255, 255, 0.3), transparent),
                linear-gradient(45deg, #2fc4fa, #1FADE1)
              `,
              }}
              onClick={handleMicClick}
            >
              <FontAwesomeIcon icon={faMicrophone} size={isRecording ? "1x" : "1x"} color={isRecording ? "red" : "white"} />
            </button>
            {isActive && (
              <button
                className={`flex items-center justify-center mt-2 rounded-full text-center
                ${inputValue.length > 0 ? 'bg-action-active' : 'bg-action-inactive'}`}
                style={{
                  width: '38px',
                  height: '38px',
                  fontSize: '17px',
                  transform: 'translateY(12px)',
                  boxShadow: inputValue.length > 0 ? `
                0 3px 5px rgba(0, 0, 0, 0.1), 
                0 2px 3px rgba(0, 0, 0, 0.06), 
                inset 0 1px 2px rgba(255, 255, 255, 0.6),
                inset 0 -1px 3px rgba(0, 0, 0, 0.4)
                ` : `
                  0 3px 5px rgba(0, 0, 0, 0.1), 
                  0 2px 3px rgba(0, 0, 0, 0.06), 
                  inset 0 1px 2px rgba(255, 255, 255, 0.5),
                  inset 0 -1px 3px rgba(0, 0, 0, 0.4)
                `,
                  background: inputValue.length > 0 ? `
                radial-gradient(circle at center, rgba(255, 255, 255, 0.2), transparent),
                linear-gradient(45deg, #004E98, #004E98)
                ` : `
                  radial-gradient(circle at center, rgba(255, 255, 255, 0.3), transparent),
                  linear-gradient(45deg, #2fc4fa, #1FADE1)
                `,
                }}
                onClick={() => {
                  if (isRecording) {
                    stopRecording();
                  }
                  if (inputValue.length > 0) {
                    handleSubmitClick(inputValue);
                  } else {
                    if (textareaRef.current) {
                      textareaRef.current.blur();
                    }
                    setIsActive(false);
                    setInputValue(""); // Clear any current input
                    finalTranscriptRef.current = "";
                  }
                }}
                // onTouchEnd may behave differently in the context of a mobile virtual keyboard being open. I want to click the submit button while keyboard is open and have it submit.
                onTouchEnd={() => {
                  if (isRecording) {
                    stopRecording();
                  }
                  if (inputValue.length > 0) {
                    handleSubmitClick(inputValue);
                  } else {
                    if (textareaRef.current) {
                      textareaRef.current.blur();
                    }
                    setIsActive(false);
                    setInputValue(""); // Clear any current input
                    finalTranscriptRef.current = "";
                  }
                }}
              >
                <FontAwesomeIcon icon={inputValue.length > 0 ? faArrowUp : faXmark} color="white" />
              </button>
            )}
          </div>
        </div>
      ) : (
        <div className="flex flex-col items-center mt-24 space-y-10" >
          <button
            className="w-20 h-20 flex items-center justify-center text-white rounded-full"
            style={{
              transform: 'scale(1.35)', // scale up the icon size since default sizing is by integer
              boxShadow: `
                0 5px 7px rgba(0, 0, 0, 0.1), 
                0 3px 5px rgba(0, 0, 0, 0.06), 
                inset 0 2px 3px rgba(255, 255, 255, 0.4),
                inset 0 -3px 5px rgba(0, 0, 0, 0.4)
              `,
              background: `
                radial-gradient(circle at center, rgba(255, 255, 255, 0.4), transparent),
                linear-gradient(45deg, #2fc4fa, #1FADE1)
              `,
            }}
            onClick={handleMicClick}
          >
            <FontAwesomeIcon icon={faMicrophone} size="2x" color="white" />
          </button>
          <button
            className={`flex items-center justify-center rounded-full `}
            style={{
              width: '48px',
              height: '48px',
              fontSize: '20px',
              boxShadow: `
            0 3px 5px rgba(0, 0, 0, 0.1), 
            0 2px 3px rgba(0, 0, 0, 0.06), 
            inset 0 1px 2px rgba(255, 255, 255, 0.5),
            inset 0 -1px 3px rgba(0, 0, 0, 0.4)
          `,
              background: `
            radial-gradient(circle at center, rgba(255, 255, 255, 0.3), transparent),
            linear-gradient(45deg, #2fc4fa, #1FADE1)
          `,
            }}
            onClick={() => {
              setIsActive(true);
              setTimeout(() => {
                if (textareaRef.current) {
                  textareaRef.current.focus(); // Focus after delay because textarea is not rendered yet
                }
              }, 50);
            }}
          >
            <FontAwesomeIcon icon={faKeyboard} size={"1x"} color={"white"} />
          </button>
        </div>
      )}
    </div>
  );
}
