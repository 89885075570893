import React, { createContext, useState } from 'react';

// Create the Swiper context
export const SwiperContext = createContext();

// Create the SwiperProvider component
export const SwiperProvider = ({ children }) => {
    // A state to store the swiper configuration
    const [swiperConfig, setSwiperConfig] = useState({
        // Default configurations can go here
    });

    return (
        <SwiperContext.Provider value={{ swiperConfig, setSwiperConfig }}>
            {children}
        </SwiperContext.Provider>
    );
};